import PiCard from '../pi/PiCard.vue';
import {reactive, toRefs,defineComponent,onBeforeMount, onMounted, getCurrentInstance,onActivated} from 'vue';
import ToFormalOrderListUtil ,{IToFormalOrderListDataObj} from './toFormalOrderListUtil';

export default defineComponent ({
    name: 'ToFormalOrderList',
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IToFormalOrderListDataObj=reactive<IToFormalOrderListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                title:'意向订单转正式订单',
                queryParam: {},
                cardFrom:'ToFormalOrderList',
                modelComp: PiCard,
                modelMethod: utils.OrderProviderApi.buildUrl('/toFormalOrder/pageData')
            },
            otherParams:{
                activeName:'all',
                count:0,
            },
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new ToFormalOrderListUtil(proxy,dataObj);
        })
        onMounted(()=>{

        })
        onActivated(()=>{

        })
        //---------------------------computed---------------------------

        const formatPageInfo=(options:any)=>{
            return options;
        }
        const gridLoaded=(res:any)=>{
            dataObj.otherParams.count=res.count;
        }
        return{
            ...toRefs(dataObj),formatPageInfo,gridLoaded
        }
    }
});