import BaseBean from "@/utils/BaseBean";

export interface IToFormalOrderListDataObj {
    utilInst:ToFormalOrderListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
    otherParams: any
}

export default class ToFormalOrderListUtil extends BaseBean{
    public dataObj:IToFormalOrderListDataObj

    constructor(proxy:any,dataObj:IToFormalOrderListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
}